import React, { Dispatch, SetStateAction, useState } from "react";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { useStripe } from "@stripe/react-stripe-js";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import ClipLoader from "react-spinners/ClipLoader";

interface ReservationRequestData {
  companyName: string;
  companyRegion: string;
  companyZipcode: string;
  companyCrewSize: string;
  companyFleetSize: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  robotCount: number;
}

interface PropTypes {
  nextStep: () => void;
  previousStep: () => void;
  setFormData: Dispatch<SetStateAction<ContactFormData>>;
  reservationData: ReservationRequestData;
}

interface ContactFormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export default function ReservationFormStep2({
  nextStep,
  previousStep,
  setFormData,
  reservationData,
}: PropTypes) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ reValidateMode: "onChange", defaultValues: reservationData });

  const stripe = useStripe();

  const [loading, setLoading] = useState(false);

  const handleBackBtn = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    previousStep();
  };

  const handleFormSubmission = async (data: ContactFormData) => {
    setLoading(true);
    setFormData(data);

    if (reservationData.robotCount === 60) {
      try {
        const res = await fetch("/api/reservation-vip", {
          body: JSON.stringify({ ...reservationData, ...data }),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          redirect: "follow",
        });
        nextStep();
      } catch (err) {
        // FIXME: this should be handled and displayed in the ui
        console.log("error!");
        console.log(err);
      }
    } else {
      try {
        const res = await fetch("/api/reservation-checkout", {
          body: JSON.stringify({ ...reservationData, ...data }),
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          redirect: "follow",
        });
        const session = await res.json();
        stripe?.redirectToCheckout({
          sessionId: session.id,
        });
      } catch (err) {
        // FIXME: this should be handled and displayed in the ui
        console.log("error!");
        console.log(err);
      }
    }
  };

  const FormError = ({
    fieldName,
  }: {
    fieldName:
      | "companyName"
      | "companyRegion"
      | "companyZipcode"
      | "companyCrewSize"
      | "companyFleetSize"
      | "firstName"
      | "lastName"
      | "email"
      | "phone"
      | "robotCount";
  }) => {
    return (
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => (
          <div className="invalid-feedback">{message}.</div>
        )}
      />
    );
  };

  const fieldClass = (fieldName: string) => {
    let classNames = ["form-control"];

    if (errors.hasOwnProperty(fieldName)) {
      classNames.push("is-invalid");
    }
    return classNames.join(" ");
  };

  return (
    <div className="row stage-two">
      <div className="col-12">
        <h3>Tell Us About Yourself</h3>

        <form onSubmit={handleSubmit(handleFormSubmission)}>
          <div className="form-group row">
            <div className="col">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                className={fieldClass("firstName")}
                id="firstName"
                placeholder="First Name"
                autoFocus
                {...register("firstName", {
                  required: "First name is required",
                })}
              />
              <FormError fieldName="firstName" />
            </div>
            <div className="col">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                className={fieldClass("lastName")}
                id="lastName"
                {...register("lastName", {
                  required: "Last name is required",
                })}
                placeholder="Last Name"
              />
              <FormError fieldName="lastName" />
            </div>
          </div>
          <div className="form-group row">
            <div className="col">
              <label htmlFor="email">Email Address</label>
              <input
                type="email"
                className={fieldClass("email")}
                id="email"
                {...register("email", {
                  required: "Email address is required",
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: "Please enter a valid email address",
                  },
                })}
                placeholder="Email Address"
              />
              <FormError fieldName="email" />
            </div>

            <div className="col">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="phone"
                className={fieldClass("phone")}
                id="phone"
                {...register("phone", {
                  required: "Phone number is required",
                  pattern: {
                    value:
                      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
                    message: "Please enter a valid phone number",
                  },
                })}
                placeholder="Phone Number"
              />
              <FormError fieldName="phone" />
            </div>
          </div>

          <div className="actions">
            <a
              onClick={handleBackBtn}
              className="btn btn-sm btn-skew btn-light back-btn"
              href="#"
            >
              <span className="unskew">back</span>
            </a>
            <button
              type="submit"
              className="btn btn-skew btn-primary reserve-btn"
              disabled={loading}
            >
              <span className="unskew">
                {loading && (
                  <>
                    <span className="spinner">
                      <ClipLoader color="#ffffff" loading={loading} size={24} />
                    </span>
                  </>
                )}
                {reservationData.robotCount === 60 ? "Submit" : "Pay Now"}
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
