import React from "react";

interface PropTypes {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
  stroke?: string;
}

export default function BuiltToLastClock(props: PropTypes) {
  const width = props.width || 42;
  const height = props.height || 42;
  const fill = props.fill || "transparent";
  const stroke = props.stroke || "#000000";
  const classNames = props.className || "";
  return (
    <svg
      className={classNames}
      width={width}
      height={height}
      viewBox={`0 0 48 48`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M40.1859 39.652C37.6013 42.3211 34.3959 44.3086 30.856 45.4371C27.3161 46.5656 23.5518 46.7998 19.8992 46.119C16.2467 45.4382 12.8196 43.8634 9.924 41.5354C7.02836 39.2073 4.75431 36.1985 3.3049 32.7774C1.85548 29.3563 1.27582 25.6296 1.61766 21.9299C1.9595 18.2302 3.2122 14.6727 5.26392 11.5752C7.31564 8.47761 10.1025 5.93633 13.3757 4.17826C16.6489 2.42019 20.3065 1.50005 24.0219 1.5"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.022 16V25L33.978 34.05"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.2 2.36011C31.4856 2.72718 32.7359 3.20843 33.936 3.79811"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.2 8.35205C41.1302 9.31191 41.9722 10.3534 42.716 11.4641"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.856 18.5359C46.1812 19.8326 46.3894 21.1559 46.478 22.4899"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.6619 30.1841C45.2957 31.4694 44.8144 32.7191 44.2239 33.9181"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
