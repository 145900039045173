import React from "react";

interface PropTypes {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
  stroke?: string;
}

export default function DownloadFile(props: PropTypes) {
  const width = props.width || 20;
  const height = props.height || 20;
  const fill = props.fill || "transparent";
  const stroke = props.stroke || "#ffffff";
  const classNames = props.className || "";
  return (
    <svg
      className={classNames}
      width={width}
      height={height}
      viewBox={`0 0 27 26`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7002 18.6C12.7002 20.2973 13.3745 21.9252 14.5747 23.1254C15.7749 24.3257 17.4028 25 19.1002 25C20.7976 25 22.4254 24.3257 23.6257 23.1254C24.8259 21.9252 25.5002 20.2973 25.5002 18.6C25.5002 16.9026 24.8259 15.2747 23.6257 14.0745C22.4254 12.8742 20.7976 12.2 19.1002 12.2C17.4028 12.2 15.7749 12.8742 14.5747 14.0745C13.3745 15.2747 12.7002 16.9026 12.7002 18.6Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1001 15.4V21.8"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1002 21.8L16.7002 19.4"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.1001 21.8L21.5001 19.4"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.7002 7.40002H15.9002"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.7002 12.2H11.1002"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.7002 17H8.7002"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.7 21.8H3.1C2.67565 21.8 2.26869 21.6314 1.96863 21.3314C1.66857 21.0313 1.5 20.6243 1.5 20.2V2.6C1.5 2.17565 1.66857 1.76869 1.96863 1.46863C2.26869 1.16857 2.67565 1 3.1 1H14.4376C14.8617 1.00009 15.2683 1.16851 15.5683 1.46827L18.6317 4.53173C18.9315 4.83168 19.0999 5.23835 19.1 5.6624V8.2"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
