import React, { useState, MouseEvent } from "react";

import ReservationFormStep0 from "@components/ReservationFormStep0";
import ReservationFormStep1 from "@components/ReservationFormStep1";
import ReservationFormStep2 from "@components/ReservationFormStep2";
import ReservationFormStep3 from "@components/ReservationFormStep3";

import { useStripe } from "@stripe/react-stripe-js";
import { Elements, CardElement } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string
);

interface PropTypes {}

export default function ReservationForm(props: PropTypes) {
  const [stage, setStage] = useState(0);
  const [robotCount, setRobotCount] = useState(30);

  const [companyFormData, setCompanyFormData] = useState({
    companyName: "",
    companyRegion: "",
    companyZipcode: "",
    companyCrewSize: "",
    companyFleetSize: "",
  });

  const [contactFormData, setContactFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const renderStage = (stageNumber: number) => {
    switch (stageNumber) {
      case 0:
        return (
          <ReservationFormStep0
            nextStep={nextStep}
            previousStep={previousStep}
            robotCount={robotCount}
            setRobotCount={setRobotCount}
          />
        );
      case 1:
        return (
          <ReservationFormStep1
            nextStep={nextStep}
            previousStep={previousStep}
            setFormData={setCompanyFormData}
            reservationData={{
              ...companyFormData,
              ...contactFormData,
              robotCount,
            }}
          />
        );
      case 2:
        return (
          <ReservationFormStep2
            nextStep={nextStep}
            previousStep={previousStep}
            setFormData={setContactFormData}
            reservationData={{
              ...companyFormData,
              ...contactFormData,
              robotCount,
            }}
          />
        );
      case 3:
        return (
          <ReservationFormStep3
            reservationData={{
              ...companyFormData,
              ...contactFormData,
              robotCount,
            }}
          />
        );
      default:
        return (
          <ReservationFormStep0
            nextStep={nextStep}
            previousStep={previousStep}
            robotCount={robotCount}
            setRobotCount={setRobotCount}
          />
        );
    }
  };
  const nextStep = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    if (stage === 3) {
      return;
    }
    setStage(stage + 1);
  };

  const previousStep = (event?: MouseEvent<HTMLAnchorElement>) => {
    event?.preventDefault();
    if (stage === 0) {
      return;
    }
    setStage(stage - 1);
  };
  return (
    <Elements stripe={stripePromise}>
      <div className="reservation-form">{renderStage(stage)}</div>
    </Elements>
  );
}
