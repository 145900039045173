import React from "react";

interface PropTypes {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
  stroke?: string;
}

export default function PowerNotPollution(props: PropTypes) {
  const width = props.width || 20;
  const height = props.height || 20;
  const fill = props.fill || "transparent";
  const stroke = props.stroke || "#ffffff";
  const classNames = props.className || "";
  return (
    <svg
      className={classNames}
      width={width}
      height={height}
      viewBox={`0 0 49 49`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.71777 32.8C9.71777 32.8 15.7178 35.566 19.2238 32.134C26.3218 25.174 17.4898 15.398 17.3578 9.506C14.0216 10.2603 10.9113 11.7933 8.28096 13.9797C5.65066 16.1661 3.57505 18.9439 2.22377 22.086C0.0797665 27.568 2.88977 31.586 9.71777 32.8Z"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M44.4819 12.5C44.482 13.551 44.2612 14.5903 43.8339 15.5506C43.4066 16.5108 42.7824 17.3706 42.0015 18.0741C41.2207 18.7776 40.3007 19.3092 39.3013 19.6345C38.3018 19.9597 37.2452 20.0713 36.1999 19.962C34.3069 19.6993 32.5762 18.7507 31.3363 17.2962C30.0964 15.8418 29.4337 13.9828 29.4739 12.072V9.5C29.4739 9.10218 29.6319 8.72064 29.9132 8.43934C30.1945 8.15804 30.5761 8 30.9739 8H42.9739C43.3717 8 43.7532 8.15804 44.0346 8.43934C44.3159 8.72064 44.4739 9.10218 44.4739 9.5L44.4819 12.5Z"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.4819 8V2"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.4819 8V2"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.9818 20V30.5C36.9818 34.0804 35.5595 37.5142 33.0278 40.0459C30.496 42.5777 27.0623 44 23.4818 44C19.9014 44 16.4676 42.5777 13.9359 40.0459C11.4042 37.5142 9.98184 34.0804 9.98184 30.5C9.94496 27.922 10.4562 25.3655 11.4818 23"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
