import React from "react";

interface PropTypes {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
  stroke?: string;
}

export default function SeesAndResponds(props: PropTypes) {
  const width = props.width || 20;
  const height = props.height || 20;
  const fill = props.fill || "transparent";
  const stroke = props.stroke || "#ffffff";

  const classNames = props.className || "";
  return (
    <svg
      className={classNames}
      width={width}
      height={height}
      viewBox={`0 0 49 49`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 24.5C7.5 24.5 14.3 14.5 24.5 14.5C34.7 14.5 41.5 24.5 41.5 24.5C41.5 24.5 34.7 34.5 24.5 34.5C14.3 34.5 7.5 24.5 7.5 24.5Z"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.5 24.5C28.5 22.2909 26.7091 20.5 24.5 20.5C22.2909 20.5 20.5 22.2909 20.5 24.5C20.5 26.7091 22.2909 28.5 24.5 28.5C26.7091 28.5 28.5 26.7091 28.5 24.5Z"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 9.5V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H9.5"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47 9.5V5C47 4.20435 46.6839 3.44129 46.1213 2.87868C45.5587 2.31607 44.7956 2 44 2H39.5"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 39.5V44C2 44.7956 2.31607 45.5587 2.87868 46.1213C3.44129 46.6839 4.20435 47 5 47H9.5"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M47 39.5V44C47 44.7956 46.6839 45.5587 46.1213 46.1213C45.5587 46.6839 44.7956 47 44 47H39.5"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
