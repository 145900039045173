import React, { Dispatch, SetStateAction } from "react";
import Slider from 'rc-slider'

interface PropTypes {
  nextStep: () => void;
  previousStep: () => void;
  setRobotCount: Dispatch<SetStateAction<number>>;
  robotCount: number;
}

export default function ReservationFormStep0({
  nextStep,
  previousStep,
  robotCount,
  setRobotCount,
}: PropTypes) {
  const handleSliderChange = (value: number) => {
    setRobotCount(value);
  };

  return (
    <div className="row stage-zero">
      <div className="col-12 col-sm-12 col-md-12 col-lg-5">
        <p>
          M.52 reservations are open for 10 or more mowers per booking. A
          reservation secures your spot in line for M.52, giving you the
          opportunity to deploy mowers as they become available in your region.
        </p>

        <p>
          As production for your reservation nears, Scythe will partner with you
          to review your current fleet inventory, growth trajectory, and mower
          turnover to develop an M.52 Deployment Plan that will match your
          needs.
        </p>
      </div>

      <div className="col-12 mt-5 mt-sm-0 col-md-12 col-lg-6 offset-lg-1">
        <h3>Number of Mowers</h3>
        <h6>How many robots do you need in your fleet?</h6>

        <div className="slider">
          <Slider
              min={10}
              max={60}
              defaultValue={robotCount || 30}
              marks={{
                10: 10,
                20: 20,
                30: 30,
                40: 40,
                50: 50,
                60: "60+",
              }}
              step={null}
              onChange={handleSliderChange}
            />
        </div>
        <div className="pricing">
          {robotCount === 60 ? (
            ""
          ) : (
            <>
              <h6>Due Today</h6>
              <h3>
                ${100 * robotCount}
                {robotCount === 60 ? "+" : ""}
              </h3>
            </>
          )}
          <a
            onClick={nextStep}
            className="btn btn-skew btn-primary reserve-btn"
            href="#"
          >
            <span className="unskew">
              {robotCount === 60
                ? `Speak with Sales`
                : `Reserve ${robotCount} Robots Now`}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}
