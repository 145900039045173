import React, { Dispatch, SetStateAction } from "react";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import { InlineWidget, PopupButton, openPopupWidget } from "react-calendly";

interface ReservationRequestData {
  companyName: string;
  companyRegion: string;
  companyZipcode: string;
  companyCrewSize: string;
  companyFleetSize: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  robotCount: number;
}

interface PropTypes {
  reservationData: ReservationRequestData;
}

export default function ReservationFormStep3({ reservationData }: PropTypes) {
  const handleCalendlyLink = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    openPopupWidget({
      url: "https://calendly.com/scythe-isaac/30min",
      pageSettings: {
        backgroundColor: "ffffff",
        hideEventTypeDetails: false,
        hideGdprBanner: true,
        hideLandingPageDetails: false,
        primaryColor: "00b140",
        textColor: "4d5055",
      },
      prefill: {
        email: reservationData.email,
        name: `${reservationData.firstName} ${reservationData.lastName}`,
      },
    });
  };
  return (
    <div className="row stage-three">
      <div className="col-3">
        <img className="confirmation-img" src="/img/modal-bg.jpg" />
      </div>
      <div className="col-9">
        <h3>What happens next?</h3>
        <p>
          Schedule a meeting to talk with us about your reservation at a time
          that's convenient for you through the Calendly link below. That's when
          a Scythe team member will introduce themselves, discuss an M.52
          deployment plan, and process your booking.
        </p>

        <a
          onClick={handleCalendlyLink}
          className="btn btn-skew btn-lg btn-primary calendly-btn"
          href="#"
        >
          <span>Schedule a Meeting with Scythe</span>
        </a>

        <h3>Follow Scythe on Social</h3>
        <p>
          Catch behind-the-scenes stories, watch the production of M.52 ramp up,
          and see autonomy in action across our social media channels.
        </p>
        <div className="social-links col-6">
          <a
            className="text-muted mx-1"
            href="https://www.linkedin.com/company/scythe-robotics"
            target="_blank"
            rel="noopener noreferrer"
            title="LinkedIn"
          >
            <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
          </a>
          <a
            className="text-muted mx-1"
            href="https://twitter.com/ScytheRobotics"
            target="_blank"
            rel="noopener noreferrer"
            title="Twitter"
          >
            <FontAwesomeIcon icon={faTwitter} size="2x" />
          </a>
          <a
            className="text-muted mx-1"
            href="https://www.instagram.com/scytherobotics/"
            target="_blank"
            rel="noopener noreferrer"
            title="Instagram"
          >
            <FontAwesomeIcon icon={faInstagram} size="2x" />
          </a>
          <a
            className="text-muted mx-1"
            href="https://www.facebook.com/scytherobotics/"
            target="_blank"
            rel="noopener noreferrer"
            title="Facebook"
          >
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
          <a
            className="text-muted mx-1"
            href="https://www.youtube.com/c/ScytheRobotics"
            title="YouTube"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faYoutube} size="2x" />
          </a>
        </div>
      </div>
    </div>
  );
}
