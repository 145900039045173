import React from "react";

interface PropTypes {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
  stroke?: string;
}

export default function Stripes(props: PropTypes) {
  const width = props.width || 20;
  const height = props.height || 20;
  const fill = props.fill || "#ffffff";
  const stroke = props.stroke || "#ffffff";
  const classNames = props.className || "";
  return (
    <svg
      className={classNames}
      width={width}
      height={height}
      viewBox={`0 0 31 49`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.0833 34.875H1.70833C1.25526 34.875 0.820734 35.055 0.500359 35.3754C0.179985 35.6957 0 36.1303 0 36.5833C0 37.0364 0.179985 37.4709 0.500359 37.7913C0.820734 38.1117 1.25526 38.2917 1.70833 38.2917H17.0833C17.5364 38.2917 17.9709 38.1117 18.2913 37.7913C18.6117 37.4709 18.7917 37.0364 18.7917 36.5833C18.7917 36.1303 18.6117 35.6957 18.2913 35.3754C17.9709 35.055 17.5364 34.875 17.0833 34.875Z"
        fill={fill}
      />
      <path
        d="M1.70833 4.12501H39.2917C39.7447 4.12501 40.1793 3.94503 40.4996 3.62465C40.82 3.30428 41 2.86976 41 2.41668C41 1.9636 40.82 1.52908 40.4996 1.2087C40.1793 0.888328 39.7447 0.708344 39.2917 0.708344H1.70833C1.25526 0.708344 0.820734 0.888328 0.500359 1.2087C0.179985 1.52908 0 1.9636 0 2.41668C0 2.86976 0.179985 3.30428 0.500359 3.62465C0.820734 3.94503 1.25526 4.12501 1.70833 4.12501Z"
        fill={fill}
      />
      <path
        d="M39.2917 9.25H1.70833C1.25526 9.25 0.820734 9.42998 0.500359 9.75036C0.179985 10.0707 0 10.5053 0 10.9583C0 11.4114 0.179985 11.8459 0.500359 12.1663C0.820734 12.4867 1.25526 12.6667 1.70833 12.6667H39.2917C39.7447 12.6667 40.1793 12.4867 40.4996 12.1663C40.82 11.8459 41 11.4114 41 10.9583C41 10.5053 40.82 10.0707 40.4996 9.75036C40.1793 9.42998 39.7447 9.25 39.2917 9.25Z"
        fill={fill}
      />
      <path
        d="M34.1667 17.7917H1.70833C1.25526 17.7917 0.820734 17.9717 0.500359 18.292C0.179985 18.6124 0 19.0469 0 19.5C0 19.9531 0.179985 20.3876 0.500359 20.708C0.820734 21.0284 1.25526 21.2084 1.70833 21.2084H34.1667C35.0728 21.2084 35.9419 21.5683 36.5826 22.2091C37.2234 22.8498 37.5833 23.7189 37.5833 24.625C37.5833 25.5312 37.2234 26.4002 36.5826 27.041C35.9419 27.6817 35.0728 28.0417 34.1667 28.0417H30.3229C30.2096 28.0417 30.101 27.9967 30.0209 27.9166C29.9408 27.8365 29.8958 27.7279 29.8958 27.6146V24.6934C29.8958 24.5347 29.8517 24.3792 29.7683 24.2443C29.6849 24.1094 29.5655 24.0003 29.4237 23.9294C29.2818 23.8584 29.1229 23.8284 28.965 23.8426C28.807 23.8569 28.6561 23.9148 28.5292 24.01L21.6958 29.0359C21.5892 29.1148 21.5025 29.2175 21.4425 29.3357C21.3824 29.4539 21.3507 29.5845 21.3499 29.7171C21.3491 29.8497 21.3791 29.9807 21.4377 30.0997C21.4962 30.2187 21.5817 30.3224 21.6873 30.4026L28.5206 35.5549C28.6685 35.6658 28.8483 35.7258 29.0331 35.7258C29.1655 35.7261 29.296 35.695 29.4141 35.6352C29.5572 35.5659 29.6781 35.458 29.7632 35.3237C29.8483 35.1894 29.8942 35.034 29.8958 34.875V31.8854C29.8958 31.7722 29.9408 31.6635 30.0209 31.5834C30.101 31.5034 30.2096 31.4584 30.3229 31.4584H34.1667C35.979 31.4584 37.7171 30.7384 38.9986 29.4569C40.2801 28.1754 41 26.4373 41 24.625C41 22.8127 40.2801 21.0746 38.9986 19.7931C37.7171 18.5116 35.979 17.7917 34.1667 17.7917Z"
        fill={fill}
      />
      <path
        d="M17.0833 26.3333H1.70833C1.25526 26.3333 0.820734 26.5133 0.500359 26.8337C0.179985 27.1541 0 27.5886 0 28.0417C0 28.4948 0.179985 28.9293 0.500359 29.2497C0.820734 29.57 1.25526 29.75 1.70833 29.75H17.0833C17.5364 29.75 17.9709 29.57 18.2913 29.2497C18.6117 28.9293 18.7917 28.4948 18.7917 28.0417C18.7917 27.5886 18.6117 27.1541 18.2913 26.8337C17.9709 26.5133 17.5364 26.3333 17.0833 26.3333Z"
        fill={fill}
      />
    </svg>
  );
}
