import React, {
  Dispatch,
  SetStateAction,
  useState,
  ChangeEvent,
  MouseEvent,
} from "react";

import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import DownloadFile from "./svg/DownloadFile";

interface ReservationRequestData {
  companyName: string;
  companyRegion: string;
  companyZipcode: string;
  companyCrewSize: string;
  companyFleetSize: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  robotCount: number;
}

interface PropTypes {
  nextStep: () => void;
  previousStep: () => void;
  setFormData: Dispatch<SetStateAction<CompanyFormData>>;
  reservationData: ReservationRequestData;
}

interface CompanyFormData {
  companyName: string;
  companyRegion: string;
  companyZipcode: string;
  companyCrewSize: string;
  companyFleetSize: string;
}

export default function ReservationFormStep1({
  nextStep,
  previousStep,
  setFormData,
  reservationData,
}: PropTypes) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ reValidateMode: "onChange", defaultValues: reservationData });

  const [showLegalModal, setShowLegalModal] = useState(false);
  const [legalAck, setLegalAck] = useState(false);
  const [showLegalInvalid, setShowLegalInvalid] = useState(false);

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setShowLegalInvalid(false);
      setLegalAck(true);
    } else {
      setLegalAck(false);
    }
  };

  const handleFormSubmission = (data: CompanyFormData) => {
    if (!legalAck) {
      setShowLegalInvalid(true);
    }

    if (legalAck) {
      setFormData(data);
      nextStep();
    }
  };

  const handleBackBtn = (event: MouseEvent) => {
    event.preventDefault();
    previousStep();
  };

  const handleTermsClick = (event: MouseEvent) => {
    event.preventDefault();
    setShowLegalModal(true);
  };

  const closeLegalModal = () => {
    setShowLegalModal(false);
  };

  const FormError = ({
    fieldName,
  }: {
    fieldName:
      | "companyName"
      | "companyRegion"
      | "companyZipcode"
      | "companyCrewSize"
      | "companyFleetSize"
      | "firstName"
      | "lastName"
      | "email"
      | "phone"
      | "robotCount";
  }) => {
    return (
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => (
          <div className="invalid-feedback">{message}.</div>
        )}
      />
    );
  };

  const fieldClass = (fieldName: string) => {
    let classNames = ["form-control"];

    if (errors.hasOwnProperty(fieldName)) {
      classNames.push("is-invalid");
    }
    return classNames.join(" ");
  };
  return <>
    <div className="row stage-one">
      <div className="col-12">
        <h3>Tell Us About Your Company</h3>

        <form onSubmit={handleSubmit(handleFormSubmission)}>
          <div className="form-group row">
            <div className="col-12">
              <label htmlFor="companyName">Company Name</label>
              <input
                type="text"
                className={fieldClass("companyName")}
                id="companyName"
                placeholder="Company Name"
                autoFocus
                {...register("companyName", {
                  required:
                    "Please enter a company name. We only allow reservations for landscaping businesses",
                })}
              />
              <FormError fieldName="companyName" />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12 col-sm-6">
              <label htmlFor="companyRegion">Region / Location</label>
              <input
                type="text"
                className={fieldClass("companyRegion")}
                id="companyRegion"
                {...register("companyRegion", {
                  required: "Please enter a region or location",
                })}
                placeholder="Region / Location"
              />
              <FormError fieldName="companyRegion" />
            </div>
            <div className="col-12 mt-3 col-sm-6">
              <label htmlFor="companyZipcode">Zipcode</label>
              <input
                type="text"
                className={fieldClass("companyZipcode")}
                id="companyZipcode"
                {...register("companyZipcode", {
                  required: "Please enter a valid 5-digit zipcode",
                  maxLength: {
                    value: 5,
                    message: "Please enter a valid 5-digit zipcode",
                  },
                  pattern: {
                    value: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
                    message: "Please enter a valid 5-digit zipcode",
                  },
                })}
                placeholder="Zipcode"
              />
              <FormError fieldName="companyZipcode" />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-12 col-sm-6">
              <label htmlFor="companyCrewSize">How Large is Your Crew?</label>
              <select
                className={fieldClass("companyCrewSize")}
                id="companyCrewSize"
                {...register("companyCrewSize", {
                  required: "Please select a crew size",
                })}
              >
                <option value="">How Large is Your Crew?</option>
                <option value="1-25">1-25 Landscapers</option>
                <option value="25-50">25-50 Landscapers</option>
                <option value="50-100">50-100 Landscapers</option>
                <option value="100-500">100-500 Landscapers</option>
                <option value="500+">500+ Landscapers</option>
              </select>
              <FormError fieldName="companyCrewSize" />
            </div>
            <div className="col-12 col-sm-6">
              <label htmlFor="companyFleetSize">
                How Large is Your Mower fleet?
              </label>
              <select
                className={fieldClass("companyFleetSize")}
                id="companyFleetSize"
                {...register("companyFleetSize", {
                  required: "Please select a fleet size",
                })}
              >
                <option value="">How Large is Your Mower fleet?</option>
                <option value="1-25">1-25 Mowers</option>
                <option value="25-50">25-50 Mowers</option>
                <option value="50-100">50-100 Mowers</option>
                <option value="100-500">100-500 Mowers</option>
                <option value="500+">500+ Mowers</option>
              </select>
              <FormError fieldName="companyFleetSize" />
            </div>
          </div>

          <div className="form-group row">
            <div
              className={`legal-checkbox col ${
                showLegalInvalid ? "is-invalid" : ""
              }`}
            >
              <label htmlFor="legal_checkbox">
                <input
                  className={`form-check-input`}
                  type="checkbox"
                  id="legal_checkbox"
                  name="legal_ack"
                  onChange={handleCheckbox}
                />
                <span>
                  By checking this box, I affirm I have read and agree to the{" "}
                  <a href="#" onClick={handleTermsClick}>
                    terms and conditions
                  </a>
                  .
                </span>
              </label>
            </div>
            {showLegalInvalid && (
              <div className="invalid-feedback checkbox-invalid">
                You must check this box to continue.
              </div>
            )}
          </div>
          <div className="actions">
            <a
              onClick={handleBackBtn}
              className="btn btn-sm btn-skew btn-light back-btn"
              href="#"
            >
              <span className="unskew">back</span>
            </a>
            <button
              type="submit"
              className={`btn btn-skew btn-primary reserve-btn ${
                showLegalInvalid ? "disabled" : ""
              }`}
            >
              <span className="unskew">Continue</span>
            </button>
          </div>
        </form>
      </div>
    </div>

    <Modal
      isOpen={showLegalModal}
      onRequestClose={closeLegalModal}
      className="scythe-modal terms-modal"
      contentLabel="Reservation Terms and Conditions"
    >
      <div className="scythe-modal-wrapper legal-modal">
        <div className="scythe-modal-full-width">
          <div className="scythe-modal-header">
            <a href="/legal/reservation-terms-and-conditions.pdf" download>
              <DownloadFile stroke="#00b140" /> Download PDF
            </a>
            <a
              className="btn-close"
              href="#"
              onClick={(e) => closeLegalModal()}
            >
              <FontAwesomeIcon icon={faTimes} size="lg" color="#00b140" />
            </a>
          </div>
          <div className="scythe-modal-content">
            <h2>Reservation Terms and Conditions</h2>
            <p>
              These Reservation Terms and Conditions (the “Agreement”) between
              Scythe Robotics, Inc. (“Scythe”) and you (“You” or “Your”) shall
              become effective upon the payment of the Reservation Fee.
            </p>
            <p>
                <strong>1. Reservation.</strong> Your reservation is the first step in obtaining M.52 mowers. It provides you the 
                  opportunity to order M.52 mowers, up to the quantity you have reserved, when we offer you an 
                  available delivery slot(s). Your reservation becomes effective upon payment in full of the reservation 
                  fee and agreement to these terms (the “<strong>Reservation Date</strong>”).
              </p>
              <p>
                The order in which we offer delivery slots will be influenced by the order in which reservations are 
                made (reservations with an earlier Reservation Date will have preference over others). But other 
                limitations and circumstances must also be factored into determining delivery slot priority. We will 
                determine delivery slot priority among reservation holders at our discretion according to various 
                operational limitations and circumstances. We will keep you apprised of the forecast for delivery 
                slots and work with you to match your delivery slots to your operational readiness.
              </p>
              <p>
                <strong>2. Order Process.</strong> While this reservation secures your right for delivery slots, it does not constitute an 
                  agreement to deliver (through a lease, sale, or otherwise) an M.52 mower. When we offer to you 
                  and you accept a delivery slot(s), we will send to you a separate, definitive agreement to complete 
                  the order process. Additional charges will be due to complete your order, including a delivery and 
                  set-up fee. If we offer you a delivery slot(s) and you do not conclude the ordering process with a 
                  definitive agreement, we will offer the delivery slot(s) to others and maintain your reservation, but 
                  we will downgrade your priority and offer you new delivery slot(s) at a later date at our discretion.
              </p>
              <p>
                <strong>3. Reservation Fee Applied Toward Delivery and Set-Up Fee.</strong> Your reservation fee will be retained by 
                  us and credited against future fees due when you complete your order, with a single $100 
                  reservation fee to be credited against the delivery and set-up fee for one delivered M.52.
              </p>
              <p>
                <strong>4. M.52 Mower Under Development.</strong> M.52 remains under development and its features and 
                  capabilities will continue to evolve. This reservation is not a guarantee that you will receive a 
                  particular version or design of the M.52 mower, or that the M.52 mowers you receive will have 
                  certain capabilities, or that you will ultimately be able to complete your order for M.52 mowers. If 
                  we elect to stop the development of the M.52 mower, you will be notified and your reservation will 
                  be canceled and we will refund in full your remaining reservation fees not applied to any fulfilled 
                  mower order.
              </p>
              <p>
                <strong>5. Cancellation.</strong> You may cancel your reservation, in whole or in part, by providing written notice to us 
                  at the contact address or email below, with the cancellation taking effect thirty (30) days after 
                  receipt of your notice. Your reservation fees for any canceled reservations will be refunded to you in 
                  full thirty (30) days after your cancellation takes effect. 
              </p>
              <p>
                <strong>6. Non-Transferable.</strong> This reservation is not transferable or assignable without the prior written 
                consent of Scythe, not to be unreasonably withheld.
              </p>
              <p><strong>7. Not an Escrow, No Interest.</strong> The reservation fee held by Scythe is not an escrow
               nor a trust and Scythe will not hold reservation fees separately from other funds nor pay any interest.
              </p>
              <p><strong>8. Publicity Permission.</strong> Scythe may publicize Customer&apos;s reservation of M.52 mowers in 
              any public or private presentations to others, including on Scythe&apos;s website.
              </p>
              <p><strong>9. Relationship.</strong> Customer and Scythe are in a customer commercial relationship only, 
              they are not and have not formed any partnership or joint venture through this agreement nor in any preceding verbal discussions.
              </p>
              <p><strong>10. Entire Agreement, Amendments.</strong> This agreement constitutes the entire agreement between Customer and Scythe 
              regarding this reservation and any arrangements, concessions, or promises prior to entering into a definitive purchase or lease agreement,
               and supersedes all prior and contemporaneous understandings or promises, verbal and written. This Agreement may only be modified in writing
                with a signature from both Customer and Scythe.
              </p>
              <p><strong>11. Interpretation.</strong> This Agreement will be interpreted and enforced in accordance with the laws of the State of Colorado
               notwithstanding its conflict of law provisions.
               </p>
              <p><strong>12. Limitation of Liability.</strong> The maximum damages that may be awarded to Customer in relation to any dispute 
              regarding this agreement or its breach is the amount of the reservation fee.
              </p>
              <p><strong>13. Disputes Settled by Arbitration.</strong> Customer and Scythe agree that any dispute or claim arising out of or relating 
              to this agreement or the breach thereof, or relating to or arising out of this reservation shall be settled exclusively through binding
               arbitration governed by the Commercial Arbitration Rules of the American Arbitration Association, and judgment on the award rendered by 
               the arbitrator(s) may be entered in any court having jurisdiction over the dispute. If Customer has any such dispute or other concern, 
               Customer shall first send written notice to the address below. If a dispute is not resolved within 60 days of such notice, then either
                party may submit it for arbitration as described above.
              </p>
              <p><strong>Contact.</strong> Please contact Scythe for any questions concerning a reservation:</p>
              <p><strong>Via mail:</strong><br></br>
              Scythe Robotics<br></br>2120 Miller Drive, Unit A<br></br>Longmont, CO 80501     
              </p>
              <p><strong>Via phone:</strong> 954.449.6550</p>
              <p><strong>Via email:</strong> reservations@scytherobotics.com</p>
          </div>
        </div>
      </div>
    </Modal>
  </>;
}
