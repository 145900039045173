import React from "react";

interface PropTypes {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
  stroke?: string;
}

export default function Maintenance(props: PropTypes) {
  const width = props.width || 42;
  const height = props.height || 42;
  const fill = props.fill || "transparent";
  const stroke = props.stroke || "#000000";
  const classNames = props.className || "";
  return (
    <svg
      className={classNames}
      width={width}
      height={height}
      viewBox={`0 0 47 47`}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.4653 27.6296L19.4884 23.6528C19.2272 23.3914 18.9171 23.1841 18.5757 23.0426C18.2344 22.9012 17.8685 22.8284 17.499 22.8284C17.1295 22.8284 16.7636 22.9012 16.4223 23.0426C16.0809 23.1841 15.7708 23.3914 15.5096 23.6528L3.579 35.5853C2.55436 36.6462 1.9874 38.067 2.00021 39.5419C2.01303 41.0168 2.6046 42.4276 3.64752 43.4705C4.69045 44.5134 6.10127 45.105 7.57612 45.1178C9.05098 45.1306 10.4719 44.5637 11.5328 43.539L23.4653 31.6065C23.9925 31.0791 24.2887 30.3639 24.2887 29.6181C24.2887 28.8723 23.9925 28.1571 23.4653 27.6296V27.6296Z"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.2077 4.31957C36.4508 3.95481 36.7718 3.64854 37.1476 3.42285C37.5234 3.19715 37.9446 3.05767 38.3808 3.01444C38.817 2.97121 39.2574 3.02533 39.6701 3.17288C40.0829 3.32043 40.4578 3.55774 40.7677 3.8677L43.2502 6.3502C43.5602 6.66015 43.7975 7.03503 43.945 7.4478C44.0926 7.86057 44.1467 8.30093 44.1035 8.73714C44.0603 9.17335 43.9208 9.59453 43.6951 9.97031C43.4694 10.3461 43.1631 10.6671 42.7983 10.9102L38.1633 14.0021C37.4633 14.4694 36.6229 14.6796 35.7853 14.5969C34.9476 14.5142 34.1645 14.1437 33.5694 13.5485C32.9742 12.9534 32.6037 12.1703 32.521 11.3327C32.4383 10.495 32.6485 9.65464 33.1158 8.95457L36.2077 4.31957Z"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.5715 13.5464L21.4758 25.642"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6126 18.6251C14.0217 19.385 12.2344 19.6333 10.4966 19.336C8.75875 19.0386 7.15574 18.2101 5.90802 16.9644C4.66031 15.7188 3.82917 14.1171 3.52892 12.3798C3.22867 10.6425 3.47407 8.85477 4.23135 7.2626L6.9126 9.9251C7.44469 10.4572 8.16636 10.7561 8.91885 10.7561C9.67134 10.7561 10.393 10.4572 10.9251 9.9251C11.4572 9.39301 11.7561 8.67134 11.7561 7.91885C11.7561 7.16636 11.4572 6.44469 10.9251 5.9126L8.2626 3.23135C9.85477 2.47407 11.6425 2.22867 13.3798 2.52892C15.1171 2.82917 16.7188 3.66031 17.9644 4.90802C19.2101 6.15574 20.0386 7.75875 20.336 9.49658C20.6333 11.2344 20.385 13.0217 19.6251 14.6126"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.3876 28.3751C34.9785 27.6152 36.7658 27.3668 38.5036 27.6642C40.2414 27.9616 41.8444 28.7901 43.0922 30.0358C44.3399 31.2814 45.171 32.8831 45.4713 34.6204C45.7715 36.3577 45.5261 38.1454 44.7688 39.7376L42.0876 37.0751C41.5555 36.543 40.8338 36.2441 40.0813 36.2441C39.3289 36.2441 38.6072 36.543 38.0751 37.0751C37.543 37.6072 37.2441 38.3289 37.2441 39.0813C37.2441 39.8338 37.543 40.5555 38.0751 41.0876L40.7376 43.7688C39.1454 44.5261 37.3577 44.7715 35.6204 44.4713C33.8831 44.171 32.2814 43.3399 31.0358 42.0922C29.7901 40.8444 28.9616 39.2414 28.6642 37.5036C28.3668 35.7658 28.6152 33.9785 29.3751 32.3876"
        stroke={stroke}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
