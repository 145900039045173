import { useState, useEffect } from "react";
import { useRouter } from "next/router";

import Image from "next/image";
import Head from "next/head";
import HeaderNavigation from "@components/HeaderNavigation";

import SeesAndRespondsIcon from "@components/svg/SeesAndResponds";
import StripesIcon from "@components/svg/Stripes";
import PowerNotPollution from "@components/svg/PowerNotPollution";

import ThreeSixty from "@components/React360Viewer";
import DownloadFile from "@components/svg/DownloadFile";
import Maintenance from "@components/svg/Maintenance";
import BuiltToLastClock from "@components/BuiltToLastClock";
import ReservationForm from "@components/ReservationForm";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Footer from "@components/Footer";
import ContactButton from "@components/ContactButton";
import SharedMetaTags from "@components/SharedMetaTags";

export default function GetScythe() {
  const router = useRouter();

  const [windowWidth, setWindowWidth] = useState<number>(0);
  function handleWindowSizeChange() {
    setWindowWidth(window.innerWidth);
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      setWindowWidth(window.innerWidth);

      window.addEventListener("resize", handleWindowSizeChange);
      return () => {
        window.removeEventListener("resize", handleWindowSizeChange);
      };
    } else {
      setWindowWidth(1024);
    }
  }, []);

  let isMobile: boolean = windowWidth <= 768;

  return (
    <>
      <Head>
        <title>
          Reserve Your Scythe M.52 Fleet Today | Scythe - Robots for a Greener
          World
        </title>
        <meta
          name="description"
          content="Multiply what your crew can do with the most advanced robotic mower on the market. Reserve today to start growing sooner."
        />
        <meta
          name="keywords"
          content="Scythe M.52, Scythe M52, Robot Mower, Self Driving Mower, Scythe Reservations, Reserve Scythe"
        />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />

        <link rel="icon" href="/img/icon.png" sizes="192x192" />
        <link rel="apple-touch-icon" href="/img/icon.png" />
        <meta name="msapplication-TileImage" content="/img/icon.png" />

        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Scythe - Robots for a Greener World"
        />
        <meta
          property="og:description"
          content="Multiply what your crew can do with the most advanced robotic mower on the market. Reserve today to start growing sooner."
        />
        <meta property="og:url" content="https://scytherobotics.com" />
        <meta property="og:site_name" content="Scythe™" />
        <meta
          property="og:image"
          content="https://scytherobotics.com/img/social-link-reservations-open.jpg"
        />
        <meta
          property="og:image:alt"
          content="Multiply what your crew can do with the most advanced robotic mower on the market. Reserve today to start growing sooner."
        />
        <meta
          property="twitter:image"
          content="https://scytherobotics.com/img/social-link-reservations-open-twitter.jpg"
        />
        <meta
          name="twitter:card"
          content="https://scytherobotics.com/img/social-link-reservations-open-twitter.jpg"
        />
        <meta
          name="twitter:description"
          content="Multiply what your crew can do with the most advanced robotic mower on the market. Reserve today to start growing sooner."
        />
      </Head>
      <HeaderNavigation />

      <div className="get-scythe-hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 hero-text">
              <h1 className="display-3">
                Drive growth while M.52 drives itself.
              </h1>
              <p>
                Multiply what your crew can do with the most advanced robotic
                mower on the market. Reserve today to start growing sooner.
              </p>

              <a className="btn btn-skew cta-btn btn-primary" href="#reserve">
                <span className="unskew">Reserve Now</span>
              </a>
              {/* 

              <a
                className="btn btn-skew btn-outline-light cta-btn ml-3"
                href="#"
                target="_blank"
              >
                <span className="unskew">Learn More</span>
              </a> */}
            </div>
          </div>
          <ul className="features row">
            <li className="col-lg-4 callout">
              <h2>
                <StripesIcon width={42} height={42} />
                <span>
                  <strong>Stripes</strong> like a Pro
                </span>
              </h2>
              <p>
                M.52 follows contours, tackles slopes, and automatically adjusts
                stripe patterns for a consistently high-quality cut &mdash; all
                on its own.
              </p>
            </li>
            <li className="col-lg-4 callout">
              <h2>
                <SeesAndRespondsIcon width={42} height={42} />
                <span>
                  <strong>Sees</strong> and <strong>Responds</strong>
                </span>
              </h2>
              <p>
                8 HDR cameras and other advanced sensors enable M.52 to identify
                obstacles on a property and safely navigate around them.
              </p>
            </li>

            <li className="col-lg-4 callout">
              <h2>
                <PowerNotPollution width={42} height={42} />
                <span>
                  <strong>Power</strong>, not Pollution
                </span>
              </h2>
              <p>
                Forget about fueling. Charge M.52 overnight and run it all day
                long with zero emissions and radically less noise.
              </p>
            </li>
          </ul>
        </div>
      </div>

      <div className="product-showcase grey-bg get-scythe-section">
        <div className="container">
          <div className="col-lg-12">
            <h1>Scythe M.52</h1>
            <h3>Step off the mower and step up your business.</h3>
          </div>

          <div className="col-lg-12">
            <div className="threesixty">
              <ThreeSixty
                className="mower-3d-view light"
                width={isMobile ? 350 : 700}
                height={isMobile ? 350 : 700}
                amount={24}
                imagePath="/3d/lg"
                fileName="{index}.png"
                loop={1}
                autoplay={true}
              />
            </div>

            <div className="col-lg-12">
              <h3>
                Self-driving technology meets commercial-grade equipment. Built
                to help your crew get more done.
              </h3>
            </div>
          </div>
        </div>
      </div>

      <div className="product-specs white-bg get-scythe-section">
        <div className="container">
          <div className="row">
            <div className="section-header col-lg-12">
              <h2>Specifications</h2>
              {/* <a href="#" target="_blank" rel="noreferrer noopener">
                <DownloadFile /> Download Spec Sheet
              </a> */}
            </div>
            <div className="col-lg-12">
              <ul className="row mb-5">
                <li className="col-6 col-sm-4 col-md-3 col-lg-3">
                  <h6>Rear-Discharge Deck</h6>
                  <span>
                    <strong>52”</strong> tri-blade
                  </span>
                </li>
                <li className="col-6 col-sm-4 col-md-3 col-lg-3">
                  <h6>Traction</h6>
                  <span>
                    <strong>20x12"</strong> tires &amp; flat-free casters
                  </span>
                </li>
                <li className="col-6 col-sm-2 col-md-3 col-lg-3">
                  <h6>Deck Power</h6>
                  <span>
                    <strong>15</strong>HP
                  </span>
                </li>
                <li className="col-6 col-sm-2 col-md-3 col-lg-3">
                  <h6>Drive Power</h6>
                  <span>
                    <strong>20</strong>HP
                  </span>
                </li>

                <li className="col-6 col-sm-2 col-md-3 col-lg-3">
                  <h6>Cut Height</h6>
                  <span>
                    <strong>1.5 - 6”</strong>
                  </span>
                </li>
                <li className="col-6 col-sm-2 col-md-3 col-lg-3">
                  <h6>Top Speed</h6>
                  <span>
                    <strong>10</strong> MPH
                  </span>
                </li>
                <li className="col-6 col-sm-2 col-md-3 col-lg-3">
                  <h6>Weight</h6>
                  <span>
                    <strong>1,100</strong> lbs
                  </span>
                </li>
                <li className="col-6 col-sm-2 col-md-3 col-lg-3">
                  <h6>Connectivity</h6>
                  <span>
                    <strong>2x</strong> LTE + WIFI
                  </span>
                </li>
              </ul>
            </div>
            <div className="col-lg-12">
              <Image
                priority={true}
                layout="intrinsic"
                className="img-fluid mx-auto text-center"
                src="/img/exploded.png"
                width={1920}
                height={973}
                alt="Exploded view diagram of the Scythe mower."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="pricing grey-bg get-scythe-section">
        <div className="container">
          <div className="row pb-2">
            <div className="section-header col-lg-12">
              <h2>Pay-as-you-mow Pricing</h2>
            </div>
            <div className="col-lg-6">
              <p>
                With our usage-based pricing model, you only pay for the acres
                you mow. Pricing per acre will vary since not all acres are the
                same, but our goal is to save you up to 40% compared to your
                mowing costs today.
              </p>
              <p>
                This also means there is no equipment to buy. No big up-front
                costs. And you’ll always have the latest technology because
                Scythe handles updates and repairs.
              </p>
            </div>
            <div className="col-lg-6">
              <ul className="prices">
                <li className="green">
                  <div>
                    <h6 className="ourgoal">
                      Our Goal? <strong>Your Growth.</strong>
                    </h6>
                    <p>
                      M.52 only bills when it's working. Scythe wins when our
                      customers do, and our pricing model reflects our
                      commitment to your success.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-12 col-lg-6 callout">
              <h2>
                <BuiltToLastClock />
                <span>
                  <strong>Built</strong> to Last
                </span>
              </h2>
              <p>
                Traditional brands make money when machines break or need
                replacing. With a pay-as-you-mow pricing, Scythe is incentivized
                to keep the mower running &mdash; just like you are.
              </p>
            </div>
            <div className="col-12 col-lg-6 callout">
              <h2 className="spendless">
                <Maintenance />
                <span>
                  <strong>Spend Less</strong> on Maintenance
                </span>
              </h2>
              <p>
                No belts or pulleys to replace. No oil or filters to change. No
                fuel to refill. Just keep the blades sharp, tires inflated, and
                battery charged, then pocket your savings.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="reserve" className="future-fleet white-bg get-scythe-section">
        <div className="container">
          <div className="row pb-2">
            <div className="section-header col-lg-12">
              <h2>Building the fleet of the future starts today</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <ReservationForm />
            </div>
          </div>
        </div>
      </div>

      <div className="faq grey-bg get-scythe-section">
        <div className="container">
          <div className="row pb-2">
            <div className="section-header col-lg-12">
              <h2>Frequently Asked Questions</h2>
            </div>
            <div className="col-lg-9">
              <Accordion allowMultipleExpanded allowZeroExpanded>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h5>When can I expect to deploy M.52 in my fleet?</h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      M.52 deployment will depend on several factors including
                      production and demand, regional availability and projected
                      mower turnover in your fleet. Scythe will contact you as
                      production for your reservation nears to build you M.52
                      Deployment Plan.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h5>What is a reservation?</h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      A reservation secures your spot in line for M.52, giving
                      you the opportunity to deploy mowers as they become
                      available in your region. A reservation does not guarantee
                      delivery by a specific date.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h5>What can I do to prepare my company for M.52?</h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      You can start by sharing information on your company with
                      us so we can help craft a detailed infrastructure plan
                      that fits seamlessly with your operations and
                      capabilities. Contact us at{" "}
                      <a href="mailto:reservations@scytherobotics.com">
                        reservations@scytherobotics.com
                      </a>{" "}
                      to begin the planning process.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h5>Is my reservation deposit refundable?</h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, your reservation deposit is fully refundable with the
                      cancellation of your reservation.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h5>Can I buy M.52?</h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      No, M.52 is only available as a usage-based rental. While
                      your reservation ensures earlier access to our M.52 mowing
                      service, it does not represent ownership.
                    </p>
                    <p>
                      This also means there is no equipment to buy. No big
                      up-front costs. And you’ll always have the latest
                      technology because Scythe handles updates and repairs.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h5>What is Pay-as-you-mow pricing?</h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Mow 40 acres this week? What about 35 acres next week?
                      M.52 knows and you'll only pay for the acreage you mow.
                    </p>
                    <p>
                      No equipment to buy, no big up-front costs, no paying for
                      machines while they aren’t being used. You keep the blades
                      sharp and the tires fresh, and Scythe takes care of the
                      rest.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h5>What is the price per acre?</h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Every jobsite is different – mowing a sports field isn't
                      the same as mowing a challenging HOA or corporate campus.
                    </p>
                    <p>
                      We’re building a solution that works for properties of all
                      levels of complexity and is intended to save you 40% or
                      more compared to mowing with manually-operated,
                      gas-powered equipment.
                    </p>

                    <p>
                      The exact price per acre will vary, taking into account
                      things like number of obstacles on the property, slopes
                      and hills, and the condition of the terrain. M.52 will
                      automatically assess the difficulty of the job as well as
                      record precisely how much acreage it mowed, which will
                      translate into consistent pricing to make sure that every
                      acre you mow is a profitable one. We’re still establishing
                      the exact pricing structure, but it’s our #1 goal to
                      ensure that it’s cheaper and more predictable than the way
                      you mow today.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h5>Can I make a reservation for just a few M.52? </h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      M.52 is currently available in bookings of 10
                    </p>
                    <p>
                      Unfortunately, at this time, we cannot support fleets that
                      require fewer than ten machines.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <h5>How do I cancel my reservation?</h5>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Contact{" "}
                      <a href="mailto:reservations@scytherobotics.com">
                        reservations@scytherobotics.com
                      </a>{" "}
                      with the details of your order to submit a cancellation
                      request. You will be refunded the reservation deposit, but
                      you will lose your spot in line for M.52 deployment.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
            <div className="col-lg-3">
              <h4>Still have questions?</h4>
              <ContactButton
                buttonText="Speak with Scythe"
                className="btn btn-skew btn-primary"
                arrow={false}
                purpose="Reservation Question"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
